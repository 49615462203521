/*==================================================
    06-COMPONENTS/SKILL
===================================================*/

.c-skill {
    width: 100%;
    position: relative;

    @media #{$non-touch} {
        &:hover .c-infobox {
            display: flex;
        }    
    }
}

.c-skill__icon {
    position: relative;
    z-index: 1;
    width: $width_elm-s;
}