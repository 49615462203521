/*==================================================
    03-GENERIC/DEFAULT
===================================================*/

* {
    padding: 0;
    margin: 0;
}

img {
    width: 100%;
    height: 100%;
}