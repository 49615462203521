/*==================================================
    06-COMPONENTS/INFOBOX
===================================================*/

.c-infobox {
    display: none;
    position: fixed;
    flex-direction: column;
    width: $width_elm-xxl;
    height: $height_elm-xxl;
    box-sizing: border-box;
    z-index: 5;
    background-color: $info-color;
    border: $thick-border $info-border-color;
    box-shadow: -10px 10px 18px 1px rgba(0,0,0,0.36);

    @media #{$touch-only} {
        &--show {
            display: flex;

            &::before {
                content: url("../../images/fantasy_theme_exit.svg");
                display: block;
                color: $dark-color;
                width: $width_elm-s;
                height: $width_elm-s;
                position: absolute;
                top: -2rem;
                right: -2rem;
            }
        }
    }

    @media #{$tablet-large} {
        left: calc(50vw - (#{$width_elm-xxl} / 2));

        &--top-left,
        &--top-right {
            top: $spacing-h-s;
        }

        &--bottom-left,
        &--bottom-right {
            bottom: $spacing-h-s;
        }
    }

    @media #{$desktop-only} {
        position: absolute;

        &--top-left {
            bottom: 50%;
            right: 50%;
        }
    
        &--bottom-left {
            top: 50%;
            right: 50%;
        }
    
        &--top-right {
            bottom: 50%;
            left: 50%;
        }
    
        &--bottom-right {
            top: 50%;
            left: 50%;
        }
    }
}

.c-infobox__header {
    background-color: $theme-color-second;
    padding: $padding-s;
    color: $light-color;
    height: 20%;
    box-sizing: border-box;
}

.c-infobox__content {
    display: flex;
    padding: $padding-s;
    width: 100%;
    height: 80%;
    box-sizing: border-box;
}

.c-infobox__img {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    box-shadow: $inset-shadow;
    border: $thick-border $dark-color;
    padding: $padding-xs;
}
.c-infobox__text {
    margin-left: $padding-s;
    text-align: left;
    width: 50%;
}

.c-infobox__list {
    margin-top: $padding-s;
}

.c-infobox__item {
    @include sword;
}