/*==================================================
    04-ELEMENTS/SCROLLBAR
===================================================*/

/* width */
::-webkit-scrollbar {
    width: 5px;
    margin-right: $spacing-w-s;
}

/* Track */
::-webkit-scrollbar-track {
    background: $bg-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $theme-color-opacity;
    opacity: 0.5;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $theme-color;
    opacity: 1;
}