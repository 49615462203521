/*==================================================
    01-SETTINGS/SIZES
===================================================*/

/** BOXES **/
/* STATIC */
$width_box-xs: 250px;
$width_box-s: 300px;
$width_box-m: 400px;
$width_box-l: 520px;
$width_box-xl: 800px;
$width_box-xxl: 1000px;
$width_box-xxxl: 1400px;

/* RESPONSIVE */
$res-width_box-s: 19.3vw;
$res-width_box-m: 31.7vw;
$res-width_box-l: 65vw;
$res-width_box-xxl: 100vw;

/** ELEMENTS **/
/* Width */
$width_elm-s: 4.7rem;
$width_elm-m: 9rem;
$width_elm-l: 15rem;
$width_elm-xl: 19.9rem;
$width_elm-xxl: 32.2rem;

/* Height */
$height_elm-s: 3rem;
$height_elm-m: 4.9rem;
$height_elm-l: 7.9rem;
$height_elm-xl: 16rem;
$height_elm-xxl: 20.7rem;