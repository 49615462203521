/*==================================================
    01-SETTINGS/COLORS
===================================================*/

$black: #110B09;
$dark-color: #3d312b;
$dark-color-opacity: #3d312b50;
$light-color: #F4E9D7;
$bg-color: #fffbf8;
$bg-color-opacity: #fffbf8a9; 
$dark-bg-color: #dbc6b5;
$theme-color: #385650; 
$theme-color-opacity: #385650c4; 
$theme-color-second: #AA926F;
$info-color: #eee7e2; 
$info-border-color: #aaa49b;