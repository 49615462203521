/*==================================================
    06-COMPONENTS/ABILITIES
===================================================*/

.c-abilities {
    width: $width_box-m;
    height: calc(#{$width_box-m} * 1.11);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('../../images/fantasy_theme_ability-bg.svg');
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$mobile} {
        width: 100%;
        height: calc(70vw * 1.11);
    }

    @media #{$big-screen-only} {
        height: calc(#{$width_box-l} * 1.11);
        width: $width_box-l;
    }
}

.c-abilities__header {
    @include profile-header;
}

.c-abilities__list {
    padding: $padding-l $padding-xxl;
    box-sizing: border-box;
}

.c-abilities__list-item {
    @include sword;
    margin-bottom: $padding-m;
}