/*==================================================
    06-COMPONENTS/CONTACT
===================================================*/

.c-contact {
    width: 100%;
    box-sizing: border-box;
}

.c-contact__header {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.c-contact__header-img {
    @include header
}

.c-contact__content {
    width: 100%;
    height: calc(#{$width_box-xxl} * 1.14);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url('../../images/fantasy_theme_contact-bg.svg');
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 $padding-xl;

    @media #{$desktop-small} {
        height: calc(#{$width_box-xl} * 1.14);
    }

    @media #{$tablet-large} {
        height: calc(#{$width_box-l} * 1.14);
    }

    @media #{$tablet-small} {
        height: calc(#{$width_box-m} * 1.14);
    }

    @media #{$mobile} {
        height: calc(70vw * 1.14);
    }

    @media #{$big-screen-only} {
        height: calc(#{$width_box-xxxl} * 1.14);
    }
}

.c-contact__list-item {
    width: 20%;
    list-style: none;
    transform: scale(1);
    transition: ease-in-out 0.2s;

    @media #{$non-touch} {
        &:hover {
            transform: scale(1.2);
            transition: ease-in-out 0.2s;
        }    
    }

    &--github {
        margin-top: 4%;
        margin-left: 20%;
    }

    &--linkedin {
        align-self: flex-end;
        margin-right: 5%;
    }

    &--email {
        margin-top: 10%;
        margin-left: 10%;
    }

    &--phone {
        align-self: flex-end;
        margin-right: 25%;
    }
}