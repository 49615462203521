/*==================================================
    02-TOOLS/ANIMATIONS
===================================================*/

@keyframes achieved {
    0% {
        transform: scale(0) rotate(0deg);
    }

    80% {
        transform: scale(2) rotate(-30deg);
    }

    100% {
        transform: scale(1) rotate(0deg);
    }
}

@keyframes expand {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes retract {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes easeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes popUp {
    0% {
        transform: scale(0);
    }

    80% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes turnPage {
    0% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

@keyframes turnPageReverse {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(180deg);
    }
}