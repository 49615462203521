/*==================================================
    04-ELEMENTS/FONTS 
===================================================*/

html {
    font-size: $global-size;
    color: $dark-color;

    @media #{$desktop-small} {
        font-size: 14px;
    }

    @media #{$tablet-large} {
        font-size: $global-size;
    }

    @media #{$tablet-small} {
        font-size: 12px;
    }

    @media #{$mobile} {
        font-size: 9px;
    }

    @media #{$big-screen-only} {
        font-size: 20px;
    }
}

mark {
    font-family: $highlight-family;
    color: $theme-color-second;
    background: none;
}

h1 {
    font-family: $h1-family;
    font-size: $h1-size;
    margin-bottom: $padding-m;
}

h2 {
    font-family: $h2-family;
    font-size: $h2-size;
}

h3 {
    font-family: $h3-family;
    font-size: $h3-size;
}

h4 {
    font-family: $h4-family;
    font-size: $h4-size;
}

p {
    font-family: $p-family;
    font-size: $p-size;
    line-height: $line-height;
}