/*==================================================
    01-SETTINGS/FONTS 
===================================================*/

@font-face{
    font-family: "Source Sans Pro Black";
    src: url("../../fonts/Source_Sans_Pro/SourceSansPro-Black.ttf");
    font-weight: 900;
    font-style: normal;
}
@font-face{
    font-family: "Source Sans Pro Bold";
    src: url("../../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}
@font-face{
    font-family: "Source Sans Pro SemiBold";
    src: url("../../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf");
    font-weight: 500;
    font-style: normal;
}
@font-face{
    font-family: "Source Sans Pro Regular";
    src: url("../../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
    font-style: normal;
}

/** FONT-FAMILY **/
$highlight-family: "Source Sans Pro Black", "sans-serif";
$h1-family: "Source Sans Pro Bold", "sans-serif";
$h2-family: "Source Sans Pro Black", "sans-serif";
$h3-family: "Source Sans Pro bold", "sans-serif";
$h4-family: "Source Sans Pro SemiBold", "sans-serif";
$btn-font-family: "Source Sans Pro Bold", "sans-serif";
$p-family: "Source Sans Pro Regular", "sans-serif";

/** FONT-SIZE **/
$global-size: 16px;
$h1-size: 2rem;
$h2-size: 1.7rem;
$h3-size: 1.5rem;
$h4-size: 0.7rem;
$h5-size: 0.5rem;
$btn-font-size: 1rem;
$p-size: .9rem;
$line-height: 1.5rem;