/*==================================================
    06-COMPONENTS/INVENTORY-NAV
===================================================*/

.c-inventory-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.c-inventory-nav__list {
    display: flex;
    width: 50%;
    max-width: $width_box-xl;

    .c-inventory-nav__list-item:last-child {
        margin-right: 0;
    }

    @media #{$desktop-small} {
        width: 60%;
    }


    @media #{$tablet-large} {
        width: 80%;
    }

    @media #{$tablet-small} {
        width: 90%;
    }

    @media #{$mobile} {
        width: 95%;
    }
}

.c-inventory-nav__list-item {
    position: relative;
    background-color: $light-color;
    margin: 0 auto;
    list-style: none;
    cursor: pointer;
    padding: $padding-m;
    color: $dark-color;
    font-family: $h1-family;
    font-size: $p-size;
    transform-origin: bottom;
    transform: scale(1);
    transition: transform ease-in-out 0.2s;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    &--current {
        border: $theme-color-second $thick-border;
        border-bottom: none;
        margin-bottom: -3px;
        transform: scale(1.2);
        z-index: 1;
    }
}