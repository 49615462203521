/*==================================================
    06-COMPONENTS/SKILLS-CONTAINER
===================================================*/

.c-skills-container {
    width: 100%;
}

.c-skills-container__content {
    display: flex;
    width: $res-width_box-xxl;
    box-sizing: border-box;
    padding: $padding-l;
    background-color: $light-color;
    border: $theme-color-second $thick-border;
    align-items: center;
    justify-content: center;

    @media #{$tablet-large} {
        flex-wrap: wrap;
        max-height: 75vh;
        overflow-y: scroll;
    }

    @media #{$tablet-small} {
        max-width: $width_box-l;
    }
}

.c-skills-container__description {
    width: 33%;
    order: 2;
    margin: $padding-l 0;

    h3,
    p {
        text-align: center;
    }

    @media #{$tablet-large} {
        order: 3;
        min-width: $width_elm-l;
    }

    @media #{$tablet-small} {
        order: 1;
    }
}
