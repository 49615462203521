/*==================================================
    06-COMPONENTS/PROJECT-PAGE
===================================================*/

.c-project-page {
    width: 38%;
    height: 100%;
    padding: 0% 5% 0% 5%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    perspective: 600px;
    transition: ease-in-out 2s;
    transform-style: preserve-3d;

    @media #{$tablet-large} {
        width: 35%;
    }

    h3 {
        font-size: 1.3rem;
    }

    &__content {
        width: 100%;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;

        &--loaded {
            animation: easeIn 1s;
            opacity: 1;
        }
    }

    &__left {
        align-self: start;
        background-image: url('../../images/fantasy_theme_left_page_bg.svg');
        
        &--turn-page{
            transform-origin: right;
            transform: rotateY(0deg);
            animation: turnPage .7s;
            z-index: 1;
        }

        @media #{$tablet-large} {
            padding: 0% 5.7% 0 5.7%;
        }
    }

    &__right {
        align-self: end;
        background-image: url('../../images/fantasy_theme_right_page_bg.svg');

        &--turn-page {
            transform-origin: left;
            transform: rotateY(0deg);
            animation: turnPage .7s;
            z-index: 1;
        }

        @media #{$tablet-large} {
            padding: 0% 5.7% 0 5.7%;
        }
    }

    &__first-page {
        background: none;
    }

    &__single-page {
        margin-right: 48%;
        align-self: start;
        background-image: url('../../images/fantasy_theme_left_page_bg.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transform-origin: right;

        &--turn-right{
            transform: rotateY(0deg);
            animation: turnPage .7s;
            z-index: 1;
        }

        &--turn-left{
            transform: rotateY(0deg);
            animation: turnPageReverse .7s;
            z-index: 1;
        }

        @media #{$tablet-large} {
            margin-right: 46%;
        }
    }

    &__header {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 5%;
    }

    &__section {
        display: flex;
        margin-bottom: 2.5%;
    }

    &__article {
        margin-bottom: 4%;
        width: 80%;

        &--team {
            width: 65%;
            margin-right: 2.5%;
        }
    }

    &__list {
        width: 80%;
    }

    &__list-item {
        @include sword;
        margin-bottom: 5%;
    }

    &__item-header {
        font-size: 1rem;
        margin-right: 2%;
    }

    &__link {
        display: none;
        width: 45px;
        margin-top: 4%;
        transition: ease-in-out 0.2s;
        &:hover {
            transform: scale(1.2);
        }

        @media #{$desktop-only} {
            display: block;   
        }
    }

    &__msg {
        position: absolute;
        font-family: $h2-family;
        color: $dark-color;
        white-space: nowrap;
        transition: ease-in-out 0.2s;
    
        @media #{$touch-only} {
            bottom: -$padding-m;
        }
    
        @media #{$non-touch} {
            top: 0;
            left: 0;
            transform:scale(0) translate(0, 0);
        }
    }
}