/*==================================================
    06-COMPONENTS/ABOUT
===================================================*/

.c-about {
    width: 100%;
    box-sizing: border-box;
}

.c-about__header {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.c-about__header-img {
    @include header
}

.c-about__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c-about__middle-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
    flex-wrap: wrap;
}