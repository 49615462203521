/*==================================================
    05-OBJECTS/WRAP
===================================================*/

.o-wrap {
    margin: auto;
}

.o-wrap__main {
    width: $width_box-xxl;

    .o-wrap__section:first-child {
        margin-top: 0;
        padding-top: $spacing-w-s;

        @media #{$tablet-large} {
            padding-top: $spacing-w-m;
        }

        @media #{$tablet-small} {
            padding-top: $spacing-w-l;
        }
    }

    .o-wrap__section:last-child {
        margin-bottom: 0;

        @media #{$tablet-small} {
            padding-bottom: $spacing-w-xl;
        }
    }

    @media #{$desktop-small} {
        width: $width_box-xl;
    }

    @media #{$tablet-large} {
        width: $width_box-l;
    }

    @media #{$tablet-small} {
        width: $width_box-m;
    }

    @media #{$mobile} {
        width: 70vw;
    }

    @media #{$big-screen-only} {
        width: $width_box-xxxl;
    }
}

.o-wrap__section {
    width: 100%;
    margin-top: $spacing-h-xs;
    margin-bottom: $spacing-h-xs;
    padding-top: $spacing-w-m;
    padding-bottom: $spacing-w-m;
}