/*==================================================
    06-COMPONENTS/PROJECT
===================================================*/

.c-project {
    list-style: none;
    margin: 0 auto;
    width: $width_elm-xl;
}

.c-project__button {
    position: relative;
    width: 100%;
    height: calc(#{$width_elm-xl} * 1.22);
    box-sizing: border-box;
    padding: $padding-l $padding-xl $padding-xl $padding-xl;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('../../images/fantasy_theme_project.svg');
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: $light-color;
    cursor: pointer;
    transform: scale(1) rotate(0);
    transition: ease-in-out 0.2s;

    @media #{$non-touch} {
        &:hover {
            transform: scale(1.2) rotate(10deg);
            transition: ease-in-out 0.2s;
            z-index: 1;
        }    
    }

    &--viewed {
        position: relative;
        opacity: .7;

        &::before {
            content: url('../../images/fantasy_theme_check.svg');
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: $width_elm-s;
        }
    }

    &--project1 {
        background-image: url('../../images/fantasy_theme_project1.svg');
    }
    &--project2 {
        background-image: url('../../images/fantasy_theme_project2.svg');
    }
    &--project4 {
        background-image: url('../../images/fantasy_theme_project4.svg');
    }
    &--project5 {
        background-image: url('../../images/fantasy_theme_project5.svg');
    }
    &--project6 {
        background-image: url('../../images/fantasy_theme_project6.svg');
    }
}

.c-project-button__header {
    margin-bottom: 50px;
    margin-left: 5px;
    text-align: center;
}

.c-project__content {
    width: $res-width_box-xxl;
    height: calc(#{$res-width_box-xxl} * 0.56);
    min-width: $width_box-xxl;
    min-height: calc(#{$width_box-xxl} * 0.56);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('../../images/fantasy_theme_projects_bg.svg');
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 0.2s;

    &--project1 {
        background-image: url('../../images/fantasy_theme_projects1_bg.svg');
    }
    &--project2 {
        background-image: url('../../images/fantasy_theme_projects2_bg.svg');
    }
    &--project4 {
        background-image: url('../../images/fantasy_theme_projects4_bg.svg');
    }
    &--project5 {
        background-image: url('../../images/fantasy_theme_projects5_bg.svg');
    }
    &--project6 {
        background-image: url('../../images/fantasy_theme_projects6_bg.svg');
    }

    @media #{$tablet-large} {
        width: 170vw;
        height: calc(170vw * 0.54);
        min-width: unset;
        min-height: unset;
        flex: none;

        &--single {
            margin-right: -100vw;
        }
    }

    @media #{$tablet-small} {
        width: 200vw;
        height: calc(200vw * 0.54);

        &--single {
            margin-right: -100vw;
        }
    }

    @media #{$mobile} {
        border-radius: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

.c-project__slider {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
}

.c-project__arrows {
    position: fixed;
    height: $height_elm-s;
    display: flex;
    width: 70vw;
    margin: auto;
    padding: $padding-s;
    background-color: $light-color;
    border: $thick-border $dark-color;
    border-radius: 100px;
    align-items: center;
    bottom: $spacing-h-xs;
    left: 15vw;
    z-index: 2;

    @media #{$tablet-small} {
        bottom: $spacing-h-l;
    }

    @media #{$mobile} {
        bottom: $spacing-h-s;
    }
}

.c-project__arrow {
    height: 100%;
    width: calc(#{$height_elm-s} * 1.1);
    
    opacity: .5;

    img {
        height: 100%;
    }

    &--left {
        align-self: flex-start;
        margin-right: auto;
    }

    &--right {
        align-self: flex-end;
        margin-left: auto;
    }

    &--active {
        opacity: 1;
        cursor: pointer;
        transition: ease-in-out 0.2s;

        @media #{$non-touch} {
            &:hover {
                transform: scale(1.2);
            }
        }
    }
}
