/*==================================================
    06-COMPONENTS/INVENTORY-LIST
===================================================*/

.c-inventory-list {
    padding: $padding-l;
    background-color: $light-color;
    border: $theme-color-second $thick-border;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;

    @media #{$tablet-small} {
        max-height: 69vh;
        overflow-y: scroll;
    }
}

.c-inventory-list__wrap {
    width: $width_box-xl;
    padding: $padding-s;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $theme-color-second;
    border: $thick-border $black;
    border-radius: 23px;

    @media #{$tablet-large} {
        width: 90%;
        margin: auto;
    }
}

.c-inventory-list__bg {
    padding: $padding-m $padding-s;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../images/fantasy_theme_inventory-bg.svg');
    border: $thick-border $black;
    border-radius: 23px;
}

.c-inventory-list__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.c-inventory-list__item {
    list-style: none;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: $padding-m $padding-l;
    width: $height_elm-l;
    height: $height_elm-l;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url('../../images/fantasy_theme_slots.svg');
    box-sizing: border-box;

    @media #{$non-touch} {
        &:hover .c-infobox {
            display: flex;
        }    
    }
}