/*==================================================
    06-COMPONENTS/BADGES
===================================================*/

.c-badges {
    display: flex;
    align-items: center;
    position: fixed;
    top: $spacing-h-s;
    right: $spacing-w-s;
    z-index: 2;

    @media #{$tablet-small} {
        flex-direction: column-reverse;
    }
}

.c-badges__list {
    display: flex;
    list-style: none;

    @media #{$tablet-small} {
        flex-direction: column;
    }
}

.c-badges__trophy {
    position: relative;
    z-index: 1;
    width: $height_elm-s;
    margin-left: -$height_elm-s;
    transition: ease-in-out 0.2s;
    cursor: pointer;
    opacity: 0.5;

    &--show {
        margin-left: $padding-l;

        @media #{$tablet-small} {
            margin-bottom: $padding-l;
            margin-left: 0;
        }
    }

    &--active {
        opacity: 1;
    }

    @media #{$non-touch} {
        &:hover {
            transform: scale(1.2) rotate(10deg);
            transition: ease-in-out 0.2s;
        }  
    }

    &:before {
        content: '';
        display: block;
        width: $width_elm-s;
        height: $width_elm-s;
        position: absolute;
        opacity: 0;
    }
}

.c-badges__trophy-num {
    font-family: $h3-family;
    position: absolute;
    left: -$padding-xs;
    top: -$padding-s;
    background-image: url('../../images/trophy-num-bg.svg');
    background-position: center;
    background-size: 100%;
    padding: $padding-xs;
    background-repeat: no-repeat;
    color: $light-color;
    width: 1rem;
    text-align: center;
    animation-name: achieved;
    animation-duration: 0.5s;

    &:before {
        transform: scale(1);
        opacity: 0;
        transition: ease-out 0.3s;
    }
}

.c-badges__item {
    margin-left: -$height_elm-s;
    width: $height_elm-s;
    transition: ease-in-out 0.2s;
    opacity: 0;
    
    &--show {
        margin-left: $padding-m;
        opacity: 1;

        @media #{$tablet-small} {
            margin-bottom: $padding-m;
            margin-left: 0;
        }
    }
}