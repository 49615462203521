/*==================================================
    06-COMPONENTS/TRAITS
===================================================*/

.c-traits {
    width: $width_box-l;
    height: calc(#{$width_box-l} * 1.03);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url('../../images/fantasy_theme_traits-bg.svg');
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-right: 5%;
    list-style: none;
    padding: $padding-xl $padding-xxl;
    justify-content: center;

    .c-traits__list-item:last-child {
        margin-bottom: 0;
    }

    @media #{$tablet-large} {
        margin-right: 0;
        margin-bottom: 5%;
    }

    @media #{$tablet-small} {
        width: 100%;
        height: calc(#{$width_box-m} * 1.03);
    }

    @media #{$mobile} {
        height: calc(70vw * 1.03);
    }

    @media #{$big-screen-only} {
        height: calc(#{$width_box-xl} * 1.03);
        width: $width_box-xl;
    }
}

.c-traits__list-item {
    margin-bottom: $padding-s;
}

.c-traits__item-header {
    margin-bottom: $padding-xs;
    border-bottom: $thick-border $dark-color;

    h3 {
        font-size: 1.3rem;
    }
}