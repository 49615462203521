/*==================================================
    06-COMPONENTS/CONTACT-TYPE
===================================================*/

.c-contact-type {
    position: relative;

    @media #{$touch-only} {
        display: flex;
        justify-content: center;
    }

    @media #{$non-touch} {
        &:hover {
            .c-contact-type__msg {
                transform:scale(1) translate(0, -50px);

                @media #{$tablet-small} {
                    transform: scale(1) translate(-42px, -50px);

                    &--email {
                        transform: scale(1) translate(0px, -50px);
                    }
                }
            }
        }    
    }
}

.c-contact-type__msg {
    position: absolute;
    font-family: $h2-family;
    color: $dark-color;
    white-space: nowrap;
    transition: ease-in-out 0.2s;

    @media #{$touch-only} {
        bottom: -$padding-m;
    }

    @media #{$non-touch} {
        top: 0;
        left: 0;
        transform:scale(0) translate(0, 0);
    }
}