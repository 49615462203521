/*==================================================
    06-COMPONENTS/GREETINGS
===================================================*/

.c-greetings {
    width: 100%;
    margin: auto;
    margin-top: $spacing-h-m;
}

.c-greetings__header {
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-bottom: $spacing-h-s;

    @media #{$tablet-large} {
        flex-direction: column;
        margin-bottom: 0;
    }
}

.c-greetings__header-text-box{
    width: 60%;

    @media #{$tablet-large} {
        width: 100%;
        text-align: center;
    }
}

.c-greetings__header-text {
    font-size: $h1-size;
    margin-bottom: 5%;
}

.c-greetings__profile-pic {
    position: relative;
    width: 35%;
    margin-right: 5%;

    &--low-res {
        position: absolute;
        top: 0;
        left: 0;
        filter: blur(5px);
    }

    @media #{$tablet-large} {
        margin-right: 0;
        margin-bottom: 5%;
    }
}

.loading {
    opacity: 1;
}

.done-loading {
    opacity: 0;
    transition: ease-in-out .3s;
    transition-delay: .3s;
}

$header-size: 13rem;

.c-greetings__content {
    display: flex;
    width: 100%;
    justify-content: center;
}

.c-greetings__content-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 auto;
    width: $width_elm-l;

    @media #{$mobile} {
        width: $width_elm-m;
    }
}