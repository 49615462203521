/*==================================================
    06-COMPONENTS/PROJECT-PAGE-IMG
===================================================*/

.c-project-page-img {
    width: 500px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--enlarged {
        width: 80vw;
        height: 96vh;
        margin: auto;
        overflow: hidden;
        position: fixed;
        top: 2vh;

        @media #{$tablet-small} {
            width: 90vw;
        }

        &--right {
            right: 7.5vw;

            @media #{$tablet-small} {
                right: 5vw;
            }
        }

        &--left {
            left: 7.5vw;

            @media #{$tablet-small} {
                left: 5vw;
            }
        }
    }

    @media #{$tablet-small} {
        width: 250px;
    }

    &__slider-wrap {
        display: flex;
        align-self: start;
        margin-top: 1%;
    }

    &__slider{
        display: flex;
        align-items: flex-start;
        height: auto;
    }

    &__slide-item{
        list-style: none;
        flex: none;
    }

    &__slide-descrp{
        text-align: justify;
        margin: auto;
        margin-top: 5%;
    }

    &__slide-img{
        height: 100%;
        margin: auto;
        position: relative;
        display: flex;
    }

    &__img--gif {
        padding: 14px 17px 10px 17px;

        @media #{$tablet-small} {
            padding: 8px 0px 8px 0px;
        }

        @media #{$mobile} {
            padding: 5px 0px 5px 0px;
        }
    }

    &__img-frame {
        position: absolute;
    }

    &__buttons {
        display: flex;
        padding: $padding-xs $padding-s;
        background-color: $light-color;
        border: $thick-border $dark-color;
        border-radius: 100px;
    }

    &__arrow {
        height: auto;
        width: calc(2rem * 1.1);
        opacity: 0.5;
        top: 45%;
        transform: scale(0.8);

        &--right {
            align-self: flex-end;
            right: 0;
        }
        &--left {
            align-self: flex-start;
            left: 0;
        }
        &--active {
            opacity: 1;
            cursor: pointer;
            transition: ease-in-out 0.2s;
    
            @media #{$non-touch} {
                &:hover {
                    transform: scale(1);
                }
            }
        }
    }

    &__expand-retract {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: auto;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #0000004d;
        opacity: 0;
        cursor: pointer;
        transition: ease-in-out 0.2s;

        &--expand {
            width: 40%;
            height: 40%;
        }
        &--retract {
            width: 40%;
            height: 40%;
        }

        @media #{$non-touch} {
            &:hover {
                opacity: 1;
            }

            &:hover > &--expand{
                animation: expand 3s;
                animation-iteration-count: infinite;
            }
            &:hover > &--retract{
                animation: retract 3s;
                animation-iteration-count: infinite;
            }
        }
    }

    &__expand-retract-img {
        position: relative;
        width: 20%;
        margin: auto;
    }
}