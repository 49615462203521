/*==================================================
    05-OBJECTS/MODAL
===================================================*/

.o-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 10;
    transition: ease-in-out 0.3s;
    box-sizing: border-box;

    &--close{
        animation-name: fadeOut;
        animation-duration: 0.3s;
    }

    &--loading {
        opacity: 0;
    }

    &--done-loading {
        opacity: 1;
        transition: ease-in-out .3s;
        transition-delay: .2s;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.o-modal__exit{
    position: fixed;
    top: 0;
    z-index: 0;
    width: 100vw;
    min-height: 100vh;
    background-color: $bg-color-opacity;
    box-shadow: $inset-shadow;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.o-modal__exit-button {
    cursor: pointer;
    display: block;
    align-self: flex-end;
    color: $dark-color;
    width: $width_elm-s;
    height: $width_elm-s;
    position: absolute;
    top: calc(#{$spacing-h-s} * 0.7);
    right: $spacing-h-s;
    z-index: 2;

    @media #{$non-touch} {
        &:hover {
            transform: scale(1.2);
            transition: ease-in-out 0.2s;
        }
    }
}

.o-modal__content {
    position: relative;
}
