/*==================================================
    06-COMPONENTS/DESCRIPTION
===================================================*/

.c-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @media #{$tablet-large} {
        width: 100%;
    }
}

.c-description__header {
    width: $width_elm-xxl;
    height: calc(#{$width_elm-xxl} * 0.19);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url('../../images/fantasy_theme_name-bg.svg');
    box-sizing: border-box;
    text-align: center;
    padding: $padding-xs;
    padding-bottom: $padding-s;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: $padding-s;

    h2 {
        font-size: $h3-size;
    }
}

.c-description__list {
    display: flex;
    list-style: none;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;
}

.c-description__list-item {
    width: $width_elm-xl;
    display: flex;
    align-items: center;
    height: calc(#{$width_elm-xl} * 0.31);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    background-image: url('../../images/fantasy_theme_dscrp-bg.svg');
    box-sizing: border-box;
    padding-right: $padding-m;
    margin: 3% 3%;

    p {
        font-size: $h4-size;
        font-family: $h4-family;
        white-space: nowrap;
    }
}

.c-description__item-header {
    width: $width_elm-m;
    height: calc(#{$width_elm-m} * 0.57);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url('../../images/fantasy_theme_profile-header1.svg');
    box-sizing: border-box;
    text-align: center;
    padding: $padding-m $padding-xs;
    padding-right: $padding-l;
    color: $light-color;
    flex: none;
    margin-right: 4%;
    display: flex;
    align-items: center;
    justify-content: center;

    h4 {
        font-family: $h3-family;
    }
}