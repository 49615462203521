/*==================================================
    06-COMPONENTS/BACKSTORY
===================================================*/

.c-backstory {
    width: 100%;
    height: calc(#{$width_box-xxl} * 0.78);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-image: url('../../images/fantasy_theme_backstory-bg.svg');
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $padding-xl;

    @media #{$desktop-small} {
        height: calc(#{$width_box-xl} * 0.78);
    }

    @media #{$tablet-large} {
        height: calc(#{$width_box-l} * 1.15);
        background-image: url('../../images/fantasy_theme_ability-bg.svg');
    }

    @media #{$tablet-small} {
        height: calc(#{$width_box-m} * 1.15);
    }

    @media #{$mobile} {
        height: calc(70vw * 1.15);
    }

    @media #{$big-screen-only} {
        height: calc(#{$width_box-xxxl} * 0.78);
    }
}

.c-backstory__header {
    @include profile-header;
    margin-top: 1%;
    margin-bottom: 2%;
}

.c-backstory__content {
    padding: $padding-l $padding-xl;
    text-align: justify;

    p {
        margin: 1% 0;
    }

    @media #{$tablet-large} {
        padding: $padding-m $padding-l;
    }
}