/*==================================================
    01-SETTINGS/SPACING
===================================================*/

/** SPACING **/

/* Vertical */
$spacing-h-xs: 1vh;
$spacing-h-s: 5vh;
$spacing-h-m: 10vh;
$spacing-h-l: 15vh;
$spacing-h-xl: 25vh;
$spacing-h-xxl: 50vh;

/* Horizontal */
$spacing-w-xs: 0.7vw;
$spacing-w-s: 3.5vw;
$spacing-w-m: 7vw;
$spacing-w-l: 14vw;
$spacing-w-xl: 35vw;


/** PADDING **/

$padding-xs: 0.5rem;
$padding-s: 1rem;
$padding-m: 1.5rem;
$padding-l: 2rem;
$padding-xl: 3rem;
$padding-xxl: 4rem;