/*==================================================
    02-TOOLS/LIST-STYLE
===================================================*/

@mixin sword {
    display: flex;
    list-style: none;

    &:before {
        content: "";
        background-image: url('../../images/sword_bulletpoint.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
        display: flex;
        align-items: flex-start;
        width: $height_elm-s;
        margin-right: 0.5rem;
        flex: none;    
    }
}